import { Show, createEffect, ErrorBoundary } from "solid-js";
import {
  useParams,
  createAsync,
  type RouteSectionProps,
  type RouteDefinition,
} from "@solidjs/router";
import { useProduct } from "~/services/products/useProduct";
import { Meta } from "@solidjs/meta";
import { ProductPriceResponse } from "~/services/roma-api/products/types";
import {
  ProductHeader,
  PricingBox,
  ProductDimensionsDetail,
  BoxQty,
  ProductColourSelector,
  ProductDescription,
  ProductDetails,
  DefaultCareInstr,
  InventoryDropdown,
} from "~/components/product/product-page";
import { useSessionContext } from "~/utils/contexts";
import { InfoBox } from "~/components/utility";
import { MOULDING } from "~/utils/products";
import { Permission } from "~/services/roma-api/account/types";

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, { available_list: true, suggestions: true }),
} satisfies RouteDefinition;

export type FormattedPricing = ProductPriceResponse & {
  availableAs?: string[];
  defaultPlantLowStock?: boolean;
  anyPlantLowStock?: boolean;
};

export default function DefaultProduct(props: RouteSectionProps) {
  const { isPartner, hasPermission } = useSessionContext();

  const params = useParams();

  const product = createAsync(() =>
    useProduct(params.sku, {
      available_list: true,
      suggestions: true,
    })
  );

  return (
    <>
      <ErrorBoundary
        fallback={(err) => (
          <div>
            ErrorBoundary in [sku]/index <br /> Error: <pre>{err.message}</pre>
          </div>
        )}
      >
        <div class="!border-t-0">
          <Meta name="Algolia crawler" content="noindex" />
          <ProductHeader product={product} />
          <Show
            when={
              isPartner() &&
              product() &&
              hasPermission(Permission.ViewPricing) &&
              !product()?.ComingSoon
            }
          >
            <PricingBox product={product} />
            <Show when={product()?.Discontinued}>
              <InfoBox class="mt-4" type="warning">
                <p>
                  This moulding is discontinued - 80% discount will be applied
                  on Length orders over 50 ft.
                </p>
              </InfoBox>
            </Show>
            <Show
              when={true}
              // when={product()?.ComingSoon && isPartner()}
            >
              <div class="bg-orange-50 border border-orange-500 text-orange-500">
                TODO: ComingSoonSubscribe
              </div>
            </Show>
            <div class="bg-orange-50 border border-orange-500 text-orange-500">
              TODO: Buy/Favourite Buttons <br />
              Wait for cart/auth
            </div>
          </Show>
        </div>
        <ProductDimensionsDetail product={product} />
        <BoxQty product={product} sku={params.sku} />
        <div class="grid grid-cols-product-headers items-center text-sm">
          <p class="font-bold">Finish</p>
          <div class="flex items-baseline gap-3 font-medium text-sm tracking-wider">
            <p>{product()?.Finish}</p>
          </div>
        </div>
        <ProductColourSelector product={product} type={MOULDING} />
        <InventoryDropdown product={product} sku={params.sku} />
        <ProductDescription description={product()?.Description as string} />
        <ProductDetails product={product} openOnLoad={true} />
        <DefaultCareInstr />
      </ErrorBoundary>
    </>
  );
}
