import {
  Router,
  useBeforeLeave,
  BeforeLeaveEventArgs,
  useIsRouting,
} from "@solidjs/router";
import { MetaProvider, Title, Meta, Link } from "@solidjs/meta";
import { FileRoutes } from "@solidjs/start/router";
import { Suspense, createEffect, For, ErrorBoundary } from "solid-js";
import { SiteContextProviderAndSessionManager } from "./components/SiteContextProviderAndSessionManager";
import "./app.css";


import { createAsync } from "@solidjs/router";
import {  getSession } from "./services/session";


// TODO:
// provide context to app
// ...list context items
//
export const VERSION = "1.9.0";

export default function App() {
  return (
    <Router
      // rootPreload={()=>getSession()}
      root={(props) => (
        <>
          <MetaProvider>
            <Title>Roma Moulding</Title>
            <Link rel="preload" as="image" href={"/img/logo-tm.svg"} />
            <Meta charset="utf-8" />
            <Meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <Meta
              name="description"
              content="Roma Moulding is a world leader in high-quality Italian-made framing."
            />
            {/* <For each={fonts}>{([path, format]) => (
                      <Link
                        rel="preload"
                        type={format}
                        href={`/fonts/${path}`}
                        as="font"
                        crossorigin="anonymous"
                      />
                    )}</For> */}
            {/* <Meta
              name="google-site-verification"
              content="ryYTCNkx1lih8rehf-vtX_E5H8GfFSO0y1x2xwGC1NI"
            /> */}
            {/* <ErrorBoundary
            fallback={(err, reset) => {
              return (
                <div>
                  An error occured...
                  <pre>{JSON.stringify(err, null, 4)}</pre>
                  <button onClick={reset}>Reset</button>
                </div>
              );
            }}
          > */}
            <Suspense>
              <SiteContextProviderAndSessionManager>
                <Suspense>{props.children}</Suspense>
              </SiteContextProviderAndSessionManager>
            </Suspense>
          </MetaProvider>
          {/* </ErrorBoundary> */}
        </>
      )}
    >
      <FileRoutes  />
    </Router>
  );
}
