import { Suspense, For, type Component, type FlowComponent } from "solid-js";
import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  A,
} from "@solidjs/router";
import { Title } from "@solidjs/meta";
import Body from "~/components/Body";
import Breadcrumb from "~/components/Breadcrumb";
import { MetaDetails } from "~/components/utility";
import { Icon } from "solid-heroicons";
import { chevronRight } from "solid-heroicons/outline";

import { getBuilderFAQEntry } from "~/services/builder";

export const route = {
  preload: ({ params }) => getBuilderFAQEntry(params.slug),
} satisfies RouteDefinition;

export default function FAQ(props: RouteSectionProps) {
  const content = createAsync(() => getBuilderFAQEntry(props.params.slug));

  const Accordion: Component<{
    index: number;
    question: string;
    answer: string;
  }> = (props) => {
    return (
      <div
        class="border-b py-5 md:py-10 first:pt-5"
        id={`question-${props.index}`}
      >
        <li class="">
          <input
            type="checkbox"
            class="accordion-input peer sr-only "
            id={props.question}
          />
          <label
            for={props.question}
            class="
            accordion-label
            cursor-pointer 
            md:cursor-default
            md:pointer-events-none
  
            flex
            justify-between 
            items-center
          
            peer-checked:mb-2
            peer-checked:-ml-2
  
            peer-checked:md:ml-0
            peer-checked:md:mb-0
  
            transition-all
            duration-[750ms]"
          >
            <div class="font-bold">{props.question}</div>
            <Icon
              class="block md:hidden accordion-icon transition-[transform] duration-200"
              path={chevronRight}
              width="15"
            />
          </label>
          <div
            class="
            block
            md:pt-3
            text-sm
            text-gray-500
            leading-7
            max-h-0 
            md:max-h-max
            peer-checked:max-h-96
            
  
            overflow-hidden
            transition-all
            duration-700"
            innerHTML={props.answer}
          />
        </li>
      </div>
    );
  };

  const ReturnButton: FlowComponent = (props) => {
    return (
      <A href="/support">
        <button class="py-2 px-3 text-xs border border-black hover:bg-black hover:text-white transition-colors rounded-full ">
          {props.children}
        </button>
      </A>
    );
  };

  return (
    <Suspense>
      <Title>RomaMoulding.com - Support Centre - {content()?.name}</Title>
      <MetaDetails
        title={content()?.data?.seoPageTitle}
        description={content()?.data?.seoPageDescription}
        keywords={content()?.data?.seoKeywords}
      />
      <Body>
        <div class="boundary-outer">
          <Breadcrumb
            breadcrumbs={[
              {
                pageTitle: "Home",
                url: "/",
              },
              {
                pageTitle: "Support",
                url: "/Support",
              },
              {
                pageTitle: "Support Centre",
                url: "/support",
              },
              {
                pageTitle: content()?.name,
                url: "./",
              },
            ]}
          />
          <div class="flex justify-between items-center py-10">
            <h2 class="text-4xl font-bold">{content()?.name}</h2>
            <ReturnButton>Return to Support Centre</ReturnButton>
          </div>
          <section class="flex w-full gap-x-20">
            <nav class="hidden md:block">
              <h2 class="text-xl font-bold ">Questions</h2>
              <ul class="text-lg font-bold flex flex-col gap-5 h-screen sticky top-0 pt-5">
                <For each={content()?.data?.faqItem}>
                  {(item, index) => (
                    <li>
                      <a href={`#question-${index()}`}>{item.question}</a>
                    </li>
                  )}
                </For>
              </ul>
            </nav>
            <main class="w-full md:basis-3/4">
              <h2 class="hidden md:block text-xl font-bold">Answers</h2>
              <h2 class="md:hidden text-xl font-bold">FAQ</h2>
              <ul>
                <For each={content()?.data?.faqItem}>
                  {(item, index) => (
                    <Accordion
                      question={item.question}
                      answer={item.answer}
                      index={index()}
                    />
                  )}
                </For>
              </ul>
            </main>
          </section>
          <div class="flex justify-end py-10">
            <ReturnButton>Return to Support Center</ReturnButton>
          </div>
        </div>
      </Body>
    </Suspense>
  );
}
