import { Show, For, createMemo, Suspense } from "solid-js";
import { Title } from "@solidjs/meta";
import {
  RouteDefinition,
  RouteSectionProps,
  createAsync,
} from "@solidjs/router";
import Banner from "~/components/Banner";
import Breadcrumb from "~/components/Breadcrumb";
import Body from "~/components/Body";
import StoryThumbnail from "~/components/StoryThumbnail";
import {
  getBuilderPressEntry,
  getBuilderPressEntries,
} from "~/services/builder";
import { MetaDetails } from "~/components/utility";

export const route = {
  preload: ({ params }) => getBuilderPressEntry(params.slug),
} satisfies RouteDefinition;

export default function PressSingle(props: RouteSectionProps) {
  const content = createAsync(() => getBuilderPressEntry(props.params.slug));
  const recents = createAsync(() => getBuilderPressEntries(3));
  const formattedDate = createMemo(() => {
    if (!content()) return "";
    return new Date(content()?.data?.publishedDate)
      .toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");
  });

  return (
    <Suspense>
      <Title>RomaMoulding.com - Press - {content()?.name}</Title>
      <MetaDetails
        title={content()?.name}
        description={content()?.data?.description}
        keywords={content()?.data?.keywords}
      />
      <h1 class="hidden">{content()?.name}</h1>
      <Body class="mt-6">
        <header>
          <Show when={content()?.data}>
            <Banner
              image={content()?.data?.banner}
              layout={"Two-Thirds"}
              headerType="Text"
              header={content()?.name}
              subHeader={`${
                content()?.data?.tags?.[0] ?? ""
              } ∙ ${formattedDate()}`}
            />
          </Show>
        </header>
        <section class="max-w-3xl mx-auto mt-8 relative flex flex-col gap-8 pb-8">
          <Breadcrumb
            breadcrumbs={[
              {
                pageTitle: "Home",
                url: "/",
              },
              {
                pageTitle: "Press",
                url: "/press",
              },
              {
                pageTitle: content()?.name,
                url: `/press/${content()?.data?.slug}`,
              },
            ]}
          />
          {/* <div class="lg:absolute lg:-left-32 -top-1">
              <Share
                pageTitle={content().name}
                pageDescription={content().data.description}
                keywords={content().data.seoKeywords}
                shareImage={content().data.banner}
                url={`/press/${content().data.slug}`}
                isHorizontal={true}
              />
            </div> */}
          <article innerHTML={content()?.data?.body} class="" />
          <Show when={content()?.data?.tags}>
            <div class="flex flex-row gap-3 text-sm font-medium">
              <p>Tagged:</p>
              <ul class="flex flex-row gap-3">
                <For each={content()?.data?.tags}>
                  {(item) => <li class="text-roma-dark-grey">{item}</li>}
                </For>
              </ul>
            </div>
          </Show>
        </section>
        <Show when={recents()}>
          <section class="border-t pt-8 flex flex-col gap-8 items-center pb-16">
            <h4 class="text-2xl font-bold">Recent Stories</h4>
            <div class="grid md:grid-cols-3 gap-8">
              <For each={recents()}>
                {(item) => (
                  <StoryThumbnail
                    title={item.name}
                    href={`/press/${item.data?.slug}`}
                    image={item.data?.banner}
                    bannerAlt={item.data?.bannerAlt}
                    description={item.data?.description || ""}
                    category={item.data?.tags?.[0]}
                    date={new Date(item.data?.publishedDate)}
                  />
                )}
              </For>
            </div>
          </section>
        </Show>
      </Body>
    </Suspense>
  );
}
