import { Show, createEffect, For } from "solid-js";
import {
  type RouteSectionProps,
  type RouteDefinition,
  createAsync,
} from "@solidjs/router";
import { Title } from "@solidjs/meta";
import { getBuilderPageData } from "~/services/builder";
import { Content } from "@builder.io/sdk-solid";
import { builderComponents } from "~/utils/builder/componentList";
import { useSessionContext } from "~/utils/contexts";
import {
  useCart,
  retrieveCart,
  addToCartAction,
  deleteCartAction,
} from "~/services/cart";
import {
  useFavourites,
  getFavourites,
  addFavouriteAction,
  deleteFavouriteAction,
} from "~/services/favourites";
import { useAction } from "@solidjs/router";
import { PT } from "~/utils/products";

// TODO
// ? do the components need to be registered somewhere? reminder for Monday.

export const route = {
  preload: ({ params }) => {
    // throw an error in here if builder data is empty? or redirect?
    // or display a NotFound component below?
    getBuilderPageData(`/${params.index}`);
    // TODO: redirect or show notfound component
  },
} satisfies RouteDefinition;

export default function Home(props: RouteSectionProps) {
  const data = createAsync(() => getBuilderPageData(props.location.pathname));
  const { session, toggleFav, isFavourited } = useSessionContext();

  const addToCart = useAction(addToCartAction);
  const addDummyItemToCurrentCart = async () => {
    const DUMMY_ITEM = {
      Type: "length",
      Quantity: 1,
      Plant: "",
      SubItems: [{ SKU: "10776", Moulding: "10776", Length: 5 }],
      Cutting: "fiveAndBalance",
    };

    await addToCart(DUMMY_ITEM);
  };

  const addToFavourites = useAction(addFavouriteAction);
  const deleteFromFavourites = useAction(deleteFavouriteAction);

  // const favourites = createAsync(() => getFavourites());
  // const favourites = useFavourites();

  const testItems = [
    { sku: "99900", type: PT.MOULDING },
    { sku: "99909", type: PT.MOULDING },
    { sku: "2030", type: PT.MOULDING },
    { sku: "2031", type: PT.MOULDING },
    { sku: "2032", type: PT.MOULDING },
  ];

  return (
    <div>
      <Show when={data()}>
        <Title>
          {data()?.data?.title && props.location.pathname !== "/"
            ? `${data()?.data?.title} | `
            : ""}
          Roma Moulding
        </Title>
        {/* <button
          class="border-2 border-red-500 text-red-500"
          onClick={addDummyItemToCurrentCart}
        >
          ADD DUMMY TO CARTTTTT
        </button> */}
        <details class="border-2 border-orange-500 flex space-x-4 ">
          {/* <p class="text-lg text-orange-500">
            TODO: Add dummy button to add item to curr cart, see if updates in
            header
          </p> */}
          <summary>Session Stuff</summary>
          <div class="w-1/2 border border-orange-200 p-2">
            Session:
            <pre class="whitespace-pre text-xs">
              {JSON.stringify(
                session(),
                (k, v) => {
                  if (k == "token") {
                    return (v as string).substring(0, 15) + "...";
                  }
                  return v;
                },
                4
              )}
            </pre>
          </div>
          <div class="w-1/2 border border-orange-200 p-2">
            {/* <pre class="whitespace-pre text-xs">
              {favourites() &&
                JSON.stringify(Object.fromEntries(favourites()), null, 4)}
            </pre> */}
          </div>
        </details>
        {/* TESTING FAVS */}
        {/* <div class="border-2 border-red-500 p-2">
          <p>TEST FAVOURITES</p>
          <div class="flex space-x-2">
            <For each={testItems}>
              {(prod) => (
                <div class="flex flex-col aspect-square border border-blue-500 grow">
                  <p>{prod.sku}</p>
                  <p>{prod.type}</p>
                  <br />
                  <button
                    onClick={() => {
                      toggleFav(prod.sku, prod.type);
                    }}
                  >
                    TOGGLE
                  </button>
                  <p class={`mt-auto ${isFavourited(prod.sku, prod.type) ? "text-green-500" : "text-red-500"}`}>
                    FAV? {isFavourited(prod.sku, prod.type) ? "TRUE" : "FALSE"}
                  </p>
                </div>
              )}
            </For>
          </div>
          <div class="mt-2 border-2 border-red-500 p-2">
            <p>MANUAL DELETE?</p>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const form = e.target as HTMLFormElement;
                const formData = new FormData(form);
                const id = formData.get("fav-id");
                await deleteFromFavourites(id as string);
                form.reset();
              }}
            >
              <input type="text" name="fav-id" class="border border-red-500" />
              <button type="submit">DELETE</button>
            </form>
          </div>
        </div> */}

        <Content
          model="page"
          apiKey={import.meta.env.VITE_BUILDER_APIKEY}
          content={data()}
          customComponents={builderComponents}
        />
      </Show>
    </div>
  );
}
