import { createSignal, onMount, onCleanup } from "solid-js";
import type { Accessor, Setter } from "solid-js";
import { isServer } from "solid-js/web";
import { throttle } from "@solid-primitives/scheduled";

export const createScrollableNav = (): [
  Accessor<boolean>,
  Accessor<boolean>,
  Setter<boolean>
] => {
  let lastPosition = 0;
  const [visible, setVisible] = createSignal(true);
  const [top, setTop] = createSignal(true);

  const update = throttle(() => {
    const delta = lastPosition - window.scrollY;
    const state = delta >= 0;
    lastPosition = window.scrollY;
    if (visible() != state) {
      if (window.scrollY > 150) {
        setVisible(state);
      } else {
        setVisible(true);
      }
    }

    const topState = window.scrollY <= 0;
    if (topState != top()) setTop(topState);
  }, 250);

  if (!isServer) {
    onMount(() => {
      document.addEventListener("scroll", update, { passive: true });
      onCleanup(() => document.removeEventListener("scroll", update));
    });
  }
  return [visible, top, setVisible];
};
