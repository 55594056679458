import { Show, createMemo, For } from "solid-js";
import { createAsync, type RouteDefinition } from "@solidjs/router";
import { useAccountProfile } from "~/services/account";
import { AccountPanel } from "~/components/account";
import type { AccountCommunication } from "~/services/roma-api/account/types";

export const route = {
  preload: () => useAccountProfile({ communications: true }),
} satisfies RouteDefinition;

export default function Notifications() {
  const profile = createAsync(() =>
    useAccountProfile({ communications: true })
  );

  const groups = createMemo(() => {
    if (!profile()?.Communications) return null;
    return profile()!.Communications!.reduce((memo, contact) => {
      if (contact.Email == "") return memo;
      if (!memo[contact.Type]) {
        memo[contact.Type] = [contact];
      } else {
        memo[contact.Type].push(contact);
      }
      return memo;
    }, {} as Record<AccountCommunication["Type"], AccountCommunication[]>);
  });

  return (
    <AccountPanel class="pb-20 max-w-6xl">
      <h2 class="text-3xl font-bold">Contact Emails</h2>
      <section class="flex flex-col divide-y child:py-10">
        <div class="flex flex-col gap-8">
          <div>
            <h3 class="text-base font-bold">Account Emails</h3>
            <p class="text-sm">
              Purchase notifications will be sent to the following addresses on
              file. Contact our customer care team to request a change.
            </p>
          </div>
          <div class="flex flex-col gap-4 space-y-4">
            <Show
              fallback={
                <div class="flex justify-center p-10 lg:p-20 border border-grey-500">
                  No contact emails have been setup on this account. Please
                  contact Roma Customer Care for additional assistance.
                </div>
              }
              when={groups() != null}
            >
              <For
                each={Object.entries(
                  groups() as Record<
                    AccountCommunication["Type"],
                    AccountCommunication[]
                  >
                )}
              >
                {([group, contacts]) => (
                  <>
                    <div class="space-y-3">
                      <h4 class="text-lg font-medium">{group}</h4>
                      <ul class="ml-5">
                        <For each={contacts}>
                          {(contact) => (
                            <li class="list-disc text-base text-roma-dark-grey">
                              {contact.Name} ({contact.Email})
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                    <hr />
                  </>
                )}
              </For>
            </Show>
          </div>
          <div>
            <a
              href="mailto:info@romamoulding.com?subject=Notification%20emails"
              class="block text-sm text-roma-blue font-medium"
            >
              Request a change
            </a>
          </div>
        </div>
        {/* <div class="grid grid-cols-[max-content_1fr] gap-x-3">
            <input type="checkbox" id="updates" />
            <label for="updates" class="text-base font-bold">
              Subscribe to Critical Updates (Recommended)
            </label>
            <p class="col-start-2 text-sm text-roma-dark-grey">
              Receive service emails regarding service outages, changes in
              hours, policy update.
            </p>
          </div>
          <div class="grid grid-cols-[max-content_1fr] gap-x-3">
            <input type="checkbox" id="discounts" />
            <label for="discounts" class="text-base font-bold">
              Subscribe to Exclusive Discounts
            </label>
            <p class="col-start-2 text-sm text-roma-dark-grey">
              Be the first to know about sales and discounts on Roma Moulding
              products.
            </p>
          </div> */}
      </section>
    </AccountPanel>
  );
}
