import { type ParentComponent, createSignal, createEffect } from "solid-js";
import {
  useIsRouting,
  createAsync,
  useAction,
  action,
  reload,
  useSubmission,
  cache,
} from "@solidjs/router";
import { SiteContext, SessionContext } from "~/utils/contexts";
import { createScrollableNav } from "~/utils/scroll";
import { createBreakpoints } from "@solid-primitives/media";
import createAnalytics from "@solid-primitives/analytics";
import { trackGTAG, trackFBQ } from "~/utils/analytics";
import { usePrefersDark } from "@solid-primitives/media";
import { VERSION } from "~/app";
import { getBuilderGlobalData } from "~/services/builder";
import {
  getSession,
  setSession as SS,
  setSessionAction,
  getIsPartner,
  logoutAction,
} from "~/services/session";
import {
  useFavourites,
  getFavourites,
  addFavouriteAction,
  deleteFavouriteAction,
} from "~/services/favourites";
import { RomaSessionData } from "~/services/session/session";
import { Permission } from "~/services/roma-api/account/types";
import { arrowTrendingUp } from "solid-heroicons/solid";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";

// ! DUMMIES

const session: Record<string, string> = {
  name: "TESTNAME",
  token: "TESTTOKEN",
  favourites: "",
  recentlySearched: "",
};

const addToRecentlySearched = (value: string) => {
  console.log("TEST - addToRecentlySearch >> ", value);
  // if (!value || value === "") return;
  // const arr = JSON.parse(session.recentlySearched) || [];
  // let location = -1;
  // // see if value already exists
  // for (const i in arr) {
  //   if (arr[i] === value) {
  //     location = parseInt(i);
  //     break;
  //   }
  // }
  // // it does not exist, unshift
  // if (location === -1) {
  //   arr.unshift(value);
  // } else {
  //   // remove the existing value and unshift
  //   arr.splice(location, 1);
  //   arr.unshift(value);
  // }

  // const trimmed = arr.slice(0, 5);
  // setSession("recentlySearched", JSON.stringify(trimmed));
};

const clearRecentlySearched = () => {
  console.log("TEST - clearRecentlySearch");
};

const isDark = usePrefersDark();
const [isDarkMode, setIsDarkMode] = createSignal(isDark());

const checkPermission = async (permissions: Permission[] | Permission) => {
  try {
    const session = await getSession();
    if (!session || !session.permissions) throw new Error();

    const permissionSet = new Set(session.permissions);
    const permissionsToCheck = Array.isArray(permissions)
      ? permissions
      : [permissions];

    return permissionsToCheck.every((permission) =>
      permissionSet.has(permission)
    );
  } catch (error) {
    return false;
  }
};

const hasPermission = cache(
  (permissions: Permission[] | Permission) => checkPermission(permissions),
  "permission"
);


export const SiteContextProviderAndSessionManager: ParentComponent = (
  props
) => {
  const session = createAsync(() => getSession());
  const useSession = (options = { deferStream: false }) =>
    createAsync(() => getSession(), options); //TODO - necessary via context?
  const useLogoutAction = useAction(logoutAction);
  const verifyIsPartner = createAsync(() => getIsPartner());
  const sessionSetter = useAction(setSessionAction);

  const addFav = useAction(addFavouriteAction);
  const delFav = useAction(deleteFavouriteAction);

  const favs = useFavourites();

  const toggleFav = async (sku: string, type: FavouriteTypeOption) => {
    // TODO - handle favourites for non signed-in users?
    if (favs()) {
      const favourited = favs()?.get(`${sku}-${type}`);

      if (favourited) {
        await delFav(favourited.ID);
        return;
      } else {
        await addFav(sku, type);
        return;
      }
    }
  };

  const isFavourited = (sku: string, type: FavouriteTypeOption) => {
    if (favs()) {
      return !!favs()?.has(`${sku}-${type}`);
    }
    return false;
  };

  const useHasPermission = (
    permissions: Permission[] | Permission,
    options = { deferStream: false }
  ) => createAsync(async () => hasPermission(permissions), options);

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession: sessionSetter,
        clearSession: useLogoutAction,
        isPartner: verifyIsPartner,
        hasPermission: useHasPermission,
        toggleFav: toggleFav,
        isFavourited,
        addToRecentlySearched,
        clearRecentlySearched,
      }}
    >
      <SiteContextProvider>{props.children}</SiteContextProvider>
    </SessionContext.Provider>
  );
};

const SiteContextProvider: ParentComponent = (props) => {
  const headerVisible = createScrollableNav();
  const isLoading = useIsRouting();
  const breakpoints = createBreakpoints({
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1536px",
  });
  const track = createAnalytics([trackGTAG]);

  const global = createAsync(() => getBuilderGlobalData());

  return (
    <SiteContext.Provider
      value={{
        headerVisible,
        isLoading,
        breakpoints,
        track,
        trackFBQ,
        VERSION,
        isDarkMode,
        setIsDarkMode,
        global,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

