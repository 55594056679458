import { Show, createSignal, createEffect } from "solid-js";
import {
  createAsync,
  type RouteDefinition,
  type RouteSectionProps,
  useSearchParams,
} from "@solidjs/router";
import { Meta } from "@solidjs/meta";
import { useProduct } from "~/services/products/useProduct";
import { GALLERYFRAME } from "~/utils/products";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { galleryFrameSizes, gfSizesByCode } from "~/utils/builtFrames";
import {
  ProductHeader,
  SwatchDisplay,
  PFGFPricingBox,
  FrameCounter,
  ProductDescription,
  ProductDetails,
  FrameChart,
} from "~/components/product/product-page";
import { Accordion } from "~/components/ui";
import { Modal } from "~/components/utility";
import { Permission } from "~/services/roma-api/account/types";

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, {
      type: GALLERYFRAME,
      available_list: true,
      suggestions: true,
    }),
} satisfies RouteDefinition;

export default function GalleryFrame(props: RouteSectionProps) {
  const { isPartner, hasPermission } = useSessionContext();
  const { global } = useSiteContext();
  const [params, setParams] = useSearchParams<{ size: string }>();
  const [qty, setQty] = createSignal(6);
  const [pricing, setPricing] = createSignal();
  const [showChart, setShowChart] = createSignal(false);

  const product = createAsync(async () => {
    return await useProduct(props.params.sku, {
      type: GALLERYFRAME,
      available_list: true,
      suggestions: true,
    });
  });

  const size = () => {
    if (isPartner() && !params.size) {
      // setParams({ size: 1620 }, { replace: false });
      return galleryFrameSizes[0].code;
    } else if (!params.size) {
      return "1620";
    }
    return params.size;
  };

  const frameSizeOptions = Object.entries(gfSizesByCode).map(([code, data]) => {
    return {
      value: code,
      label: (
        <span>
          {data.size[0]}
          <span class="text-xs mx-1">&#10005;</span>
          {data.size[1]}
        </span>
      ),
    };
  });

  return (
    <>
      <Show when={product() && global()}>
        <div class="!border-t-0">
          <Meta name="Algolia crawler" content="noindex" />
          <ProductHeader
            product={product}
            type={GALLERYFRAME}
            size={params.size}
          />
          <Show
            when={isPartner()}
            fallback={<SwatchDisplay product={product} type={GALLERYFRAME} />}
          >
            <PFGFPricingBox
              product={product}
              sizes={galleryFrameSizes}
              sizeOptions={frameSizeOptions}
              activeSize={size}
              sku={props.params.sku}
              productType={GALLERYFRAME}
              setPricing={setPricing}
              sizeGuide={() => {
                setShowChart(true);
              }}
            />
            <Show when={hasPermission(Permission.PlaceOrder)}>
              <div class="grid grid-cols-2 sm:grid-cols-3 gap-x-2">
                <FrameCounter
                  minimum={6}
                  getCount={qty}
                  updateCount={setQty}
                  label="Quantity"
                />
              </div>
            </Show>
          </Show>
          <div class="bg-orange-50 border border-orange-500 text-orange-500">
            TODO: Buy/Favourite Buttons <br />
            Wait for cart/auth
          </div>
        </div>
        <ProductDescription description={product()?.Description as string} />
        <ProductDetails product={product} hideAvailableAs>
          <ul class="list-inside list-disc pb-3 text-sm text-roma-dark-grey pl-2">
            <li>Crystal clear plexiglass</li>
            <li>Bright white mat</li>
            <li>Cardboard backing</li>
            <li>Hardware and wire included</li>
            <li>Custom made with love and care</li>
            <li>Solid wood frame made in Italy</li>
            <li>Assembled in the USA and Canada</li>
            <li>Durable and scratch resistant finish</li>
            <li>100% Happiness Guarantee</li>
          </ul>
        </ProductDetails>
        <Accordion
          name="care-instructions"
          label="Care Instructions"
          labelClass="font-bold"
          icon="Chevron"
          childClass="text-roma-dark-grey text-sm child:pb-3 leading-7"
        >
          <p>
            To clean your frame, use a soft, dry cloth with gentle pressure.
            Avoid harsh chemicals or abrasives that may cause damage. For
            acrylic or glass surfaces, apply glass cleaner to a separate, clean
            cloth or paper towel and then buff dry to remove any streaks. Proper
            care helps preserve your frame's beauty and longevity.
          </p>
        </Accordion>
        <Modal
          open={showChart}
          onClose={() => {
            setShowChart(false);
          }}
          width="5xl"
        >
          <FrameChart mouldingWidth={product()?.Width!} />
        </Modal>
      </Show>
    </>
  );
}
